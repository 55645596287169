export default function () {
    $(function () {
        $('.header__menutrigger').click(function () {
            $('body').toggleClass('menu-open');
        });

        $('.footer .menu-container h2').click(function () {
            $(this).parent().parent().toggleClass('open');
        });

        $('.footer .address-container h2').click(function () {
            $(this).parent().toggleClass('open');
        });

        $('.sticky-header-wrapper button').click(function () {
            $('body').toggleClass('overlay-menu-open');
            if ($('body').hasClass('overlay-menu-open')) {
                $('.sticky-header-wrapper button .text').text('Sluiten');
            } else {
                $('.sticky-header-wrapper button .text').text('Menu');
                // $(this).children('.text').text('Sluiten')
            }
        });

        $(window).resize(function() {
            if($(window).width() < 1025 && $('body').hasClass('overlay-menu-open')) {
                $('body').removeClass('overlay-menu-open')
            }
        })

        $(document).scroll(function() {
            if(window.scrollY > 0) {
                $('.header').addClass('mobile-bg');
            } else {
                $('.header').removeClass('mobile-bg');
            }
        });

        const options = {
            rootMargin: '0px 0px -100%',
            threshold: [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1]
        }

        const observer = new IntersectionObserver(function (entries) {
            if(entries[0].isIntersecting && !$('body').hasClass('overlay-menu-open')) {
                $('.sticky-header-wrapper').addClass('hide');
                $('.sticky-header-wrapper').removeClass('show');

            } else {
                $('.sticky-header-wrapper').addClass('show');
                $('.sticky-header-wrapper').removeClass('hide');
            }

        }, options)
        observer.observe(document.querySelector('.header'));


        const footerObserver = new IntersectionObserver(function(entries ){
            if(entries[0].isIntersecting && !$('.header .shield').hasClass('hide')) {
                $('.header .shield').addClass('hide')
            }

            if(!entries[0].isIntersecting && $('.header .shield').hasClass('hide')) {
                $('.header .shield').removeClass('hide')
            }
        }, {
            rootMargin: '0px 0px 0px',
            threshold: [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1]
        });
        footerObserver.observe(document.querySelector('.footer .logo img'));

    });

    $('li.menu-item.has-children a').on('click', function(event) {
        //event.preventDefault(); // Prevent the default behavior of the link

        let $submenu = $(this).siblings('ul');

        // Check if the submenu is already displayed with flex
        if ($submenu.hasClass('display-flex')) {
            // If it is, go to the URL of the link
            const url = $(this).attr('href');
            window.location.href = url;
        } else {
            // Otherwise, add display-flex class to the submenu
            $submenu.addClass('display-flex');
        }
    });

    // Optionally, reset the submenu display when the menu item loses focus
    $('li.menu-item.has-children a').on('blur', function() {
        $(this).siblings('ul').removeClass('display-flex');
    });
}